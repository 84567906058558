<template>
  <!-- class="fullScreen" -->
  <div :class="{ show: showEditDialog, fullScreen: distriBution }">
    <Crumbs v-if="distriBution">
      <el-breadcrumb-item slot="after">
        绩效分配方法
      </el-breadcrumb-item>
    </Crumbs>
    <div :class="{ fullScreenMain: distriBution }" v-loading="loading">
      <div
        :class="{ fullScreenMainHeader: distriBution }"
        style="margin-bottom:10px;"
      >
        商务类型:
        <el-input v-model="value" :disabled="true" style="width:200px;">
        </el-input>
      </div>

      <div :class="{ fullScreenMainCon: distriBution }">
        <el-table :data="tableData[Index]" border style="width:100%">
          <el-table-column align="center" type="index" label="序号" width="100">
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="businessType"
            label="项目类型"
            width="200"
          >
          </el-table-column> -->
          <el-table-column align="center" prop="workContent" label="工作内容">
          </el-table-column>
          <el-table-column align="center" label="商务占比规则">
            <template slot-scope="scope">
              <el-tooltip effect="dark" placement="top">
                <div v-html="scope.row.ratioRuleDetail" slot="content"></div>
                <div>{{ scope.row.ratioRule }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- 无事前 -->
          <template v-if="Index === 0">
            <el-table-column
              align="center"
              label="商务占比(总比例100%)"
              width="200"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.ratio === ''">
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="scope.row.ratioRule === '剩余比例'"
                      v-model="sum"
                      class="userRation"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                  </template>
                </template>
                <template v-else>
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      v-model="newSum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    ></el-input>
                  </template>
                </template>
              </template>
            </el-table-column>
          </template>
          <!-- 新客户 -->
          <template v-else-if="Index === 1">
            <el-table-column
              align="center"
              label="商务占比(总比例100%)"
              width="200"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.ratio === ''">
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="scope.row.ratioRule === '剩余比例'"
                      v-model="sum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    ></el-input>
                  </template>
                </template>
                <template v-else>
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      v-model="newSum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    ></el-input>
                  </template>
                </template>
              </template>
            </el-table-column>
          </template>
          <!-- 老客户 -->
          <template v-else>
            <el-table-column
              align="center"
              :disabled="businessManage.performanceStatus != 0"
              label="商务占比(总比例100%)"
              width="200"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.ratio === ''">
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                    disabled
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="scope.row.ratioRule === '剩余比例'"
                      v-model="sum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    ></el-input>
                  </template>
                </template>
                <template v-else>
                  <template v-if="scope.row.ratioRule !== '剩余比例'">
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      :min="scope.row.percentage[0]"
                      :max="scope.row.percentage[1]"
                      v-model="scope.row.ratio"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    >
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      :disabled="businessManage.performanceStatus != 0"
                      v-model="newSum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      class="userRation"
                    ></el-input>
                  </template>
                </template>
              </template>
            </el-table-column>
          </template>

          <el-table-column
            align="center"
            label="人员占比(当前人员总占比不能超过商务占比%)"
            width="400"
          >
            <template slot-scope="scope">
              <template v-for="(i, j) in scope.row.userAssignment">
                <template v-if="i.workContent == scope.row.workContent">
                  <div :key="j">
                    <span class="userName" id="userName"
                      >成员:{{ i.userName }}</span
                    ><span style="margin-left:17%;"
                      >占比:<el-input
                        :disabled="businessManage.performanceStatus != 0"
                        style="width:150px;"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        v-model="i.userRatio"
                        class="userRation"
                      ></el-input>
                    </span>
                  </div>
                  <span>
                    <template
                      v-if="scope.row.workContent === '提供有效项目信息'"
                    >
                      <span v-if="iSnum1 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template
                      v-if="scope.row.workContent === '投标文件技术方案编制'"
                    >
                      <span v-if="iSnum2 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template v-if="scope.row.workContent === '关键沟通'">
                      <span v-if="iSnum3 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template v-if="scope.row.workContent === '历史商务关系'">
                      <span v-if="iSnum4 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template
                      v-if="scope.row.workContent === '上一项目实施团队'"
                    >
                      <span v-if="iSnum5 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template
                      v-if="
                        scope.row.workContent === '售前与投标技术方案及咨询'
                      "
                    >
                      <span v-if="iSnum6 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template v-if="scope.row.workContent === '商务跟进'">
                      <span v-if="iSnum7 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template v-if="scope.row.workContent === '其他'">
                      <span v-if="iSnum8 > ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                    <template v-if="scope.row.workContent === '有效引荐'">
                      <span v-if="iSnum9 > scope.row.ratio" style="color:red;"
                        >警告: 总占比不能大于商务占比!!!</span
                      >
                    </template>
                  </span>
                </template>
                <template v-else></template>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <div v-if="opinionPerformanceRecordList.length > 0">
          <h3 style="margin-top:20px;">意见区:</h3>
          <div class="text-center">
            <template v-for="i in opinionPerformanceRecordList">
              <div class="text-center-top" :key="i.id">
                <span class="text-center-top-left">
                  <el-button
                    class="text-btn"
                    type="success"
                    disabled
                    v-if="i.isCheckPass == 1"
                    >通过</el-button
                  >
                  <el-button type="info" class="text-btn" disabled v-else
                    >不通过</el-button
                  >
                  <span class="opinion">审批意见: {{ i.opinion }}</span>
                </span>
                <span class="text-center-top-right">
                  <span class="name">处理人: {{ i.auditUserName }}</span>
                  <span class="day"
                    >审批时间: {{ i.auditDate | dateFormat }}</span
                  >
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="fullScreenOperation" v-if="distriBution && !loading">
        <el-button
          v-if="businessManage.performanceStatus != 1"
          type="primary"
          size="medium"
          class="btn"
          @click="addFn(0)"
          >暂存</el-button
        >
        <el-button
          v-if="businessManage.performanceStatus != 1"
          type="success"
          size="medium"
          @click="addFn(1)"
          >提交</el-button
        >
        <el-button size="medium" class="return" @click="returnFn"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
  },

  props: {
    showEditDialog: {
      type: Boolean,
      default: true,
    },
    ID: {
      type: String,
      default: "",
    },
    isManage: {
      //是否商务填报进来
      type: Boolean,
      default: false,
    },
    distriBution: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sum() {
      let n = 100;
      this.tableData[this.Index].forEach((v) => {
        n -= Number(v.ratio);
      });
      return n;
    },
    newSum() {
      let arr = this.tableData[this.Index];
      let n = arr[arr.length - 1].ratio;
      let k = 100;
      arr.forEach((v, i) => {
        if (v.workContent !== "其他") {
          k -= Number(v.ratio);
        }
      });
      n = k;
      return n;
    },
    iSnum1() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("提供有效项目信息" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "提供有效项目信息") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum2() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("投标文件技术方案编制" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "投标文件技术方案编制") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum3() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("关键沟通" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "关键沟通") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum4() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("历史商务关系" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "历史商务关系") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum5() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("上一项目实施团队" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "上一项目实施团队") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum6() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("售前与投标技术方案及咨询" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "售前与投标技术方案及咨询") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum7() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("商务跟进" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "商务跟进") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum8() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("其他" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "其他") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
    iSnum9() {
      let arr = this.tableData[this.Index];
      let all = null;
      arr.forEach((v, i) => {
        if ("有效引荐" === v.workContent) {
          v.userAssignment.forEach((k, j) => {
            if (k.userRatio) {
              if (k.workContent === "有效引荐") {
                all += Number(k.userRatio);
              }
            }
          });
        }
      });
      return all;
    },
  },
  data() {
    return {
      loading:false,
      value: "",
      Index: 0,
      AchievementInfo: {
        businessId: "",
        businessPerformanceRequstList: [
          {
            businessType: "",
            id: "",
            ratio: "",
            ratioRule: "",
            ratioRuleDetail: "",
            workContent: "",
            userAssignment: [{ id: "", userRatio: "" }],
          },
        ],
        status: "",
      },
      tableData: [
        [
          {
            businessType: "无事前沟通竞标类项目",
            workContent: "提供有效项目信息",
            ratioRule: "1%",
            percentage: [0,1],
            ratioRuleDetail: "",
            ratio: "",
            userAssignment: [],
          },
          {
            businessType: "无事前沟通竞标类项目",
            workContent: "投标文件技术方案编制",
            ratioRule: "0%~30%",
            percentage: [0,30],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况：询价等不需要技术方案的该项比例为0；<br/>竞争性磋商等需要简单技术方案的比例为5%；<br/>公开招标或竞争性谈判但以价格和商务为主（技术分不超过35分）的比例为15%；<br/>公开招标或竞争性谈判其技术方案占重要作用的（技术分超过35分）的比例为30%。",
          },
          {
            businessType: "无事前沟通竞标类项目",
            workContent: "关键沟通",
            ratioRule: "0%~30%",
            percentage: [0,30],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况：<br/>如无有效事中沟通的则为0；<br/>如事中沟通获取了有效信息支撑竞标的为15%；<br/>如事中沟通获得了业主方重要支持的为30%。",
          },
          {
            businessType: "无事前沟通竞标类项目",
            workContent: "其他",
            ratio: "",
            userAssignment: [],
            ratioRule: "剩余比例",
            ratioRuleDetail: "",
          },
        ],
        [
          {
            businessType: "有事前沟通新客户项目",
            workContent: "提供有效项目信息",
            ratioRule: "1%~3%",
            percentage: [1,3],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况进行合计：<br>（1）根据项目信息获取时间，如为该项目最早获知的1%，否则0.5%；<br>（2）根据项目信息丰富程度，如包含项目关键人、项目金额、项目选取方式等信息的为1%，否则0.5%；<br>（3）根据项目产生形式，如项目为省级以上政府部门公开部署的则为0，如项目为市级政府部门公开部署的为0.5%，其他为1%。",
          },
          {
            businessType: "有事前沟通新客户项目",
            workContent: "关键沟通",
            ratioRule: "25%~97% (减去有效引荐费用)",
            percentage: [25,97],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通新客户项目",
            workContent: "有效引荐",
            ratioRule: "0%~15%",
            percentage: [0,15],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "如公司员工首次引荐公司商务人员与项目方人员直接沟通的，视具体情况从关键沟通费用中调剂一定比例给引荐人员：<br>（1）引荐到项目关键决策人员的，调剂15%，<br>（2）引荐到项目重要执行人员的，调剂10%，<br>（3）引荐到项目一般人员的，调剂5%。",
          },
          {
            businessType: "有事前沟通新客户项目",
            workContent: "售前与投标技术方案及咨询",
            ratioRule: "0%~30%",
            percentage: [0,30],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通新客户项目",
            workContent: "商务跟进",
            ratio: "",
            userAssignment: [],
            ratioRule: "0%~10%",
            percentage: [0,10],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通新客户项目",
            workContent: "其他",
            ratio: "",
            userAssignment: [],
            ratioRule: "剩余比例",
            ratioRuleDetail: "",
          },
        ],
        [
          {
            businessType: "有事前沟通老客户项目",
            workContent: "提供有效项目信息",
            ratioRule: " 1%~3%",
            percentage: [1,3],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况进行合计：<br/>（1）根据项目信息获取时间，如为该项目最早获知的1%，否则0.5%；<br/>（2）根据项目信息丰富程度，如包含项目关键人、项目金额、项目选取方式等信息的为1%，否则0.5%；<br/>（3）根据项目产生形式，如项目为省级以上政府部门公开部署的则为0，如项目为市级政府部门公开部署的为0.5%，其他为1%",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "关键沟通",
            ratioRule: "25%~97%(减去历史商务关系与上一项目实施团队费用)",
            percentage: [25,97],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "历史商务关系",
            ratioRule: "关键沟通费用的0%~30%",
            percentage: [0,30],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况：<br/>（1）如与项目关键决策者进行决定性沟通的与上一项目的是同一人，则历史商务关系费用与关键沟通费用合一；<br/>（2）如需要历史商务关系人与项目关键决策者对具体项目进行辅助沟通的则按照关键沟通费用的30%计算；<br/>（3）如历史商务关系人与项目关键决策者没有进行项目具体沟通的则按照关键沟通费用的20%计算；<br/>（4）如上一项目关键沟通人员为外部合作者，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "上一项目实施团队",
            ratioRule: "关键沟通费用的0%~35%",
            percentage: [0,35],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "分为以下情况：<br/>（1）对于驻场项目服务单位委托的新项目。<br/>1）项目关键决策者或重要执行者与驻场团队负责人进行项目决定性沟通的，则驻场团队视为关键沟通人员；<br/>2）驻场单位通过驻场人员向公司提供项目信息的，驻场团队在获得提供有效项目信息费用的基础上，分配关键沟通费用的30%；<br/>3）驻场单位直接与公司非驻场人员联系并提供项目信息，同时对驻场团队持较为肯定态度的，分配关键沟通费用的20%，如不持负面态度的，分配关键沟通费用的10%，持负面态度的，不参与。<br/>（2）对于非驻场项目服务单位委托的新项目。<br/>1）项目关键决策者或重要执行者与项目实施人员进行项目决定性沟通的，则实施部门视为关键沟通人员；<br/>2）项目单位通过实施人员向公司提供项目信息的，实施部门在获得提供有效项目信息费用的基础上，分配关键沟通费用的20%；<br/>3）服务单位直接与公司非实施人员联系并提供项目信息，同时对上一项目实施团队持肯定态度的，分配关键沟通费用的10%到实施部门。",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "售前与投标技术方案及咨询",
            ratioRule: "0%~30%",
            percentage: [0,30],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "商务跟进",
            ratioRule: "0%~10%",
            percentage: [0,10],
            ratio: "",
            userAssignment: [],
            ratioRuleDetail:
              "与项目决策者进行关键性沟通的人员绩效费用，根据以下情况进行合计：<br>(1)根据承接方排他性，如为确定性选择对象的则47%，如为有限竞争性选择对象则为25%；<br>（2）根据售前方案要求程度，如不要求提供售前方案（针对性技术方案、咨询、DEMO等）的则为30%，<br>如要求提供供售前方案的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）减去3%，直至为0，<br>相应部分分配给负责售前与投标技术方案及咨询的实施部门，如果为经常性分散项目，则按照第一个项目起一年度总计计算项目金额；<br>（3）根据商务跟进情况，如基本不需要其他商务人员跟进的则为10%，<br>如需要其他商务人员简单跟进的为5%，如需要其他商务人员全面跟进的为0，如项目沟通关键人员为外部合作者，则视为需要其他商务人员全面跟进，并在商务跟进部分额外增加5%；<br>（4）根据采购方式，如直接选取的则为10%，如通过询价选取的则为8%，通过竞争性磋商或第三方采购机构选择的则为5%，通过政府公共采购平台选择的则为0；<br>（5）如由外部合作者为主开展的项目，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。",
          },
          {
            businessType: "有事前沟通老客户项目",
            workContent: "其他",
            ratioRule: "剩余比例",
            ratio: "",
            userAssignment: [],
            ratioRuleDetail: "",
          },
        ],
      ],
      ratio: "",
      newRatioL: "",
      opinionPerformanceRecordList: [],
      businessManage: [],
      dialogFormVisible: false,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    isManage: {
      immediate: true,
      handler(val, oldval) {},
    },
    businessManage: {
      deep: true,
      immediate: true,
      handler(val, oldval) {
        if (val.businessType === "WSQGTJBLXM") {
          this.value = "无事前沟通竞标类项目";
          this.Index = 0;
          return;
        } else if (val.businessType === "YSQGTXKHXM") {
          this.value = "有事前沟通新客户项目";
          this.Index = 1;
          return;
        } else if (val.businessType === "YSQGTLKHXM") {
          this.value = "有事前沟通老客户项目";
          this.Index = 2;
          return;
        }
      },
    },
    ID: {
      deep: true,
      immediate: true,
      handler(val, oldval) {
        this.getData();
      },
    },
  },
  mounted() {
    this.ratio = this.sum;
    this.newRatio = this.newSum;
    let arr = this.tableData[this.Index];
    if (this.businessManage.performanceStatus == 0) {
      arr[arr.length - 1].ratio = this.newRatio;
    } else {
      arr[arr.length - 1].ratio = this.ratio;
    }
  },
  methods: {
    async addFn(index) {
      let ratio = this.sum;
      let newRatio = this.newSum;
      let arr = this.tableData[this.Index];
      if (this.businessManage.performanceStatus == 0) {
        arr[arr.length - 1].ratio = newRatio;
      } else {
        arr[arr.length - 1].ratio = ratio;
      }
      this.AchievementInfo.businessPerformanceRequstList = arr;
      this.AchievementInfo.businessPerformanceRequstList.forEach((k) => {
        arr.forEach((v) => {
          if (k.workContent === v.workContent) {
            k.userAssignment.forEach((j) => {
              j.businessId = "";
              j.deptNames = "";
              j.phone = "";
              j.postName = "";
              j.staffId = "";
              j.userName = "";
              j.userType = "";
              j.workContent = "";
            });
          }
          k.ratioRuleDetail = "";
        });
      });
      this.AchievementInfo.businessId = this.businessManage.id;
      this.AchievementInfo.status = index;
      await this.$api.businessPerformance.saveOrUpdatePerformance(
        this.AchievementInfo
      );
      if (index === 0) {
        Message.success("暂存成功");
      } else {
        Message.success("提交成功");
      }
      this.returnFn(1);
    },
    async getData() {
      if (this.ID) {
        const id = this.ID;
        this.opinionPerformanceRecordList = [];
        this.loading = true
        console.log('1')
        const res = await this.$api.businessPerformance.getPerformanceInfo(id);
        if (res.data.businessManage) {
          this.businessManage = res.data.businessManage;
        }
        if (res.data.opinionPerformanceRecordList.length > 0) {
          this.opinionPerformanceRecordList =
            res.data.opinionPerformanceRecordList;
        }
        this.tableData[this.Index].forEach((v, i) => {
          res.data.businessPerformanceList.forEach((k, j) => {
            if (i === j) {
              v.ratio = k.ratio;
              v.id = k.id;
            }
          });
        });
        if (res.data.userAssignmentMap) {
          if (res.data.userAssignmentMap.userAssignment !== null) {
            this.tableData[this.Index].forEach((v, i) => {
              res.data.userAssignmentMap.userAssignment.forEach((k) => {
                if (v.workContent === k.workContent) {
                  v.userAssignment.push(k);
                }
              });
            });
          }
        }
        this.loading = false
        console.log('2')

      }
    },
    returnFn() {
      this.$emit("update:showEditDialog", false);
      this.$emit("updateData");
    },
  },
};
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import "@/styles/config.scss";
// .fullScreen {
//   // display: block;
// }
#userName {
  display: inline-block;
  width: 25%;
}
.text-center {
  width: 100%;
  height: 300px;
  //   border: 1px solid #000;
  overflow: auto;
  padding: 16px;
  .text-center-top {
    margin-bottom: 10px;
    color: #000;
    background-color: #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-center-top-left {
      // width: 50%;
      flex: 1;
      display: flex;
      align-items: center;
      .text-btn {
        height: 48px;
        width: 90px;
        margin-right: 20px;
      }
      .opinion {
        display: inline-block;
        margin-left: 8px;
        width: 100%;
        word-break: break-word;
        padding: 5px 0;
        font-family: PingFang SC;
        font-weight: 600;
        // font-size: 18px;
      }
    }
    .text-center-top-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      .day {
        margin-left: 10%;
        padding-right: 8px;
      }
    }
  }
}
</style>
